import React, { PureComponent as Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

import a from '../../actions'
import { qsParse } from '../../helpers'
import { getSavedState } from '../../helpers/storage'
import {
  getUser,
  getUserInfo,
  getUserTotalPlayers,
  getTheme,
  getTimeZone,
  getIsRequesting,
} from '../../reducers'
import language from '../../locale/userProfile'
import { DARK_MODE } from '../../themes/bo'

import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'

import CustomerServiceDetailForm from '../../components/forms/CustomerServiceDetailForm'
import AgentDetailForm from '../../components/forms/AgentDetailForm'
import UserInfoForAdmin from '../../components/forms/UserInfoForAdmin'
import ChangePasswordForm from '../../components/forms/ChangePasswordForm'
import WalletForm from '../../components/forms/WalletForm'

class UserProfile extends Component {
  state = { show: 0, isEditing: false };

  componentDidMount () {
    const {
      show,
      i18n,
      fetchUserInfo,
      fetchTotalSignUp,
      user,
    } = this.props

    i18n.addResourceBundle('en', 'userProfile', language.en)
    i18n.addResourceBundle('cn', 'userProfile', language.cn)

    this.setState({ show })

    user && fetchUserInfo({ userID: user.userID, role: user.role })
    user && fetchTotalSignUp({ userID: user.userID })
  }

  componentDidUpdate (preProps) {
    const { user, fetchUserInfo, fetchTotalSignUp } = this.props

    if ((preProps.user && preProps.user.userID) !== (user && user.userID)) {
      fetchUserInfo({ userID: user.userID })
      fetchTotalSignUp({ userID: user.userID })
    }
  }

  handleChangeTabs = (_, value) => {
    const { history } = this.props

    this.setState({ show: value })

    const location = {
      state: { show: value },
    }

    history.replace(location)
  };

  handleEditing = () => {
    this.setState({ isEditing: true })
  };

  handleCancel = () => {
    this.setState({ isEditing: false })
  };

  onSubmitProfile = values => {
    const { handleAgentDetailSubmit } = this.props

    const effects = ['updateCurrencySign']

    handleAgentDetailSubmit(values, {
      effects,
      cb: () => {
        this.handleCancel()
      },
    })
  };

  onSubmitChangePassword = values => {
    this.props.updateAgentPassword(values)
  };

  onSubmitWallet = values => {
    this.props.updateAgentWallet(values)
  };

  onCopied = () => {
    this.props.addMsg({
      replaceWithI18nText: 'copiedSuccess',
      variant: 'success',
    })
  }

  renderTab = () => {
    const {
      userInfo,
      totalPlayers,
      role,
      isRequesting,
      isTotalSignUpRequesting,
      t,
    } = this.props
    const { show, isEditing } = this.state

    if (!userInfo) {
      return null
    }

    switch (show) {
      default:
      case 0:
        // 詳細資料頁面的欄位有三種樣板：
        // 1. admin
        // 2. owner, parent 欄位相同
        // 3. admin, owner, parent 的 cs 欄位相同
        if (role === 'admin') {
          return <UserInfoForAdmin
            userid={userInfo.userid}
            account={userInfo.account}
            parentAccount={userInfo.parentacc}
            signUpAt={moment(userInfo.signuptime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
            loginAt={
              userInfo.logintime === 0
                ? t('neverLogin')
                : moment(userInfo.logintime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')
            }
            status={userInfo.status}
            isTestSS={userInfo.isTestSS ? 'true' : 'false'}
            onSubmit={this.onSubmitProfile}
            handleEditing={this.handleEditing}
            handleCancel={this.handleCancel}
            isEditing={() => isEditing}
            inProfile={() => true}
          />
        }

        if (role === 'owner' || role === 'parent') {
          return <AgentDetailForm
            userid={userInfo.userid}
            account={userInfo.account}
            parentAccount={userInfo.parentacc}
            signUpAt={moment(userInfo.signuptime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
            loginAt={
              userInfo.logintime === 0
                ? t('neverLogin')
                : moment(userInfo.logintime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')
            }
            totalPlayers={totalPlayers}
            commission={userInfo.commission}
            ssToken={userInfo.sstoken}
            status={userInfo.status}
            isTestSS={userInfo.isTestSS ? 'true' : 'false'}
            currencySign={userInfo.currencySign === true ? 'true' : 'false'}
            betThreshold={{
              ...userInfo.betThreshold,
              enabled: userInfo && userInfo.betThreshold && userInfo.betThreshold.enabled === true ? 'true' : 'false',
            }}
            onSubmit={this.onSubmitProfile}
            handleEditing={this.handleEditing}
            handleCancel={this.handleCancel}
            isEditing={() => isEditing}
            inProfile={() => true}
            isRequesting={isRequesting}
            isTotalSignUpRequesting={isTotalSignUpRequesting}
          />
        }

        if (role === 'cs') {
          return <CustomerServiceDetailForm
            userid={userInfo.userid}
            account={userInfo.account}
            signUpAt={moment(userInfo.signuptime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
            loginAt={
              userInfo.logintime === 0
                ? t('neverLogin')
                : moment(userInfo.logintime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')
            }
            status={userInfo.status}
            isTestSS={userInfo.isTestSS ? 'true' : 'false'}
            handleEditing={this.handleEditing}
            handleCancel={this.handleCancel}
            isEditing={() => isEditing}
            inProfile={() => true}
            isRequesting={isRequesting}
          />
        }

        if (role === 'owner' || role === 'parent') {
          return <AgentDetailForm
            userid={userInfo.userid}
            account={userInfo.account}
            parentAccount={userInfo.parentacc}
            signUpAt={moment(userInfo.signuptime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
            loginAt={
              userInfo.logintime === 0
                ? t('neverLogin')
                : moment(userInfo.logintime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')
            }
            totalPlayers={userInfo.totalPlayers}
            commission={userInfo.commission}
            ssToken={userInfo.sstoken}
            status={userInfo.status}
            isTestSS={userInfo.isTestSS ? 'true' : 'false'}
            currencySign={userInfo.currencySign === true ? 'true' : 'false'}
            betThreshold={{
              ...userInfo.betThreshold,
              enabled: userInfo.betThreshold.enabled === true ? 'true' : 'false',
            }}
            onSubmit={this.onSubmitProfile}
            handleEditing={this.handleEditing}
            handleCancel={this.handleCancel}
            isEditing={() => isEditing}
            inProfile={() => true}
            isRequesting={isRequesting}
          />
        }

        if (role === 'cs') {
          return <CustomerServiceDetailForm
            userid={userInfo.userid}
            account={userInfo.account}
            signUpAt={moment(userInfo.signuptime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
            loginAt={
              userInfo.logintime === 0
                ? t('neverLogin')
                : moment(userInfo.logintime * 1000).utcOffset(this.props.timezone * 60).format('YYYY-MM-DD HH:mm:ss')
            }
            status={userInfo.status}
            isTestSS={userInfo.isTestSS ? 'true' : 'false'}
            handleEditing={this.handleEditing}
            handleCancel={this.handleCancel}
            isEditing={() => isEditing}
            inProfile={() => true}
            isRequesting={isRequesting}
          />
        }

        return <div />
      case 1:
        return (
          <ChangePasswordForm
            account={userInfo.account}
            password=""
            confirmPassword=""
            onSubmit={this.onSubmitChangePassword}
          />
        )

      case 2:
        return (
          <WalletForm
            userid={userInfo.userid}
            // wtoken={userInfo.wtoken}
            wurl={userInfo.wurl}
            onSubmit={this.onSubmitWallet}
            handleEditing={this.handleEditing}
            handleCancel={this.handleCancel}
            isEditing={isEditing}
          />
        )
    }
  };

  render () {
    const { userInfo, t, themeMode } = this.props
    const { show } = this.state

    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            style={ themeMode === DARK_MODE ? { backgroundColor: '#585858' } : {}}
            value={show}
            onChange={this.handleChangeTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t('detail')} />
            <Tab label={t('changePwd')} />
            <Tab
              label={t('walletInfo')}
              style={{ display: userInfo && !userInfo.sw && 'none' }}
            />
          </Tabs>
        </AppBar>

        <Container>
          <GridItem form>
            <Paper>{this.renderTab()}</Paper>
          </GridItem>
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, { location }) => {
  const search = location ? qsParse(location.search) : null
  const user = getUser(state)
  const userInfo = getUserInfo(state)
  const totalPlayers = getUserTotalPlayers(state)
  const show = location.state ? location.state.show : 0
  const themeMode = getTheme(state)
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const isRequesting = getIsRequesting(state, 'handleAgentDetailSubmit')
  const isTotalSignUpRequesting = getIsRequesting(state, 'fetchTotalSignUp')

  return {
    timezone,
    themeMode,
    role: user ? user.role : null,
    user: user,
    userInfo,
    totalPlayers,
    search,
    show,
    isRequesting,
    isTotalSignUpRequesting,
  }
}

export default connect(
  mapStateToProps,
  {
    // client
    updateAgentPassword: a.client.updatePassword,
    // agent
    updateAgentWallet: a.agent.updateWallet,
    // user
    fetchUserInfo: a.user.fetchInfo,
    fetchTotalSignUp: a.user.fetchTotalSignUp,
    // event
    handleAgentDetailSubmit: a.event.handleAgentDetailSubmit,
    // msgs
    addMsg: a.msgs.add,
  },
)(withTranslation('userProfile')(UserProfile))
