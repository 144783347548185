import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { CircularProgress } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import { renderFields } from '../../components/forms/helper'
import SelectTextField from '../../components/text-fields/SelectTextField'

import MultiSelectTextField from '../../components/text-fields/MultiSelectTextField'
import RadioTextField from '../../components/text-fields/RadioTextField'
import Dialog from '../../components/Dialogs/Slide'

const yupSchema = Yup.object().shape({
  parentid: Yup.array().min(1, 'required'),
  gamecode: Yup.array().min(1, 'required'),
})

const formikEnhancer = withFormik({
  validationSchema: yupSchema,
  handleSubmit: async (values, { props, setSubmitting, setTouched }) => {
    try {
      await props.onSubmit({
        gamehall: values.brand,
        gamecode: values.gamecode.map(v => {
          return v.value
        }),
        parentid: values.parentid.map(v => {
          return v.value
        }),
      })
    } catch (error) { console.log(error) }

    setSubmitting(false)
    setTouched({ parentid: false, gamecode: false })
  },
  displayName: 'addBlackList',
  enableReinitialize: true,
})

const AddBlackList = props => {
  const {
    brand,
    isSubmitting,
    t,
    setFieldValue,
    isFetching,
    gamesOptions,
    agents,
    onGameTypeChange,
    role,
    type,
    brandOptions,
    onBrandChange,
    onParentIdChange,
  } = props

  const isCS = role === 'cs' && type === 'SYSTEM'

  const fields = [
    [
      {
        Field: SelectTextField,
        name: 'brand',
        label: t('brand'),
        fullWidth: true,
        options: brandOptions,
        onChange: ({ value }) => {
          setFieldValue('brand', value)
          onBrandChange(value)
        },
        hide: role !== 'admin' && !isCS,
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'parentid',
        label: t('parentid'),
        fullWidth: true,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        onChange: (value) => {
          onParentIdChange(value)
          setFieldValue('parentid', value)
        },
        InputLabelProps: {
          shrink: true,
        },
        options: agents.filter(({ brand: _brand }) => _brand === brand),
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'gametype',
        label: t('gametype'),
        fullWidth: true,
        onChange: onGameTypeChange,
        options: [
          { label: t('all'), value: 'all' },
          { label: t('slot'), value: 'slot' },
          { label: t('arcade'), value: 'arcade' },
          { label: t('fish'), value: 'fish' },
          { label: t('table'), value: 'table' },
          { label: t('sport'), value: 'sport' },
          { label: t('lotto'), value: 'lotto' },
          { label: t('live'), value: 'live' },
          { label: t('livegame'), value: 'livegame' },
          { label: t('dealergame'), value: 'dealergame' },
        ],
      },
    ],
    [
      {
        Field: MultiSelectTextField,
        name: 'gamecode',
        label: t('gamecode'),
        fullWidth: true,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
        closeMenuOnSelect: false,
        enabledAllOption: true,
        onChange: (value) => setFieldValue('gamecode', value),
        InputLabelProps: {
          shrink: true,
        },
        options: gamesOptions,
      },
    ],
  ]

  return (
    <Form>
      {renderFields(fields[0], props)}
      {renderFields(fields[1], props)}
      {renderFields(fields[2], props)}
      {renderFields(fields[3], props)}

      <Dialog
        variant="common"
        isSubmitting={isSubmitting}
        actionType='positive'
        text={{
          confirm: t('add', { ns: 'common' }),
          dialogTitle: t('dialogTitle'),
          dialogDescription: t('dialogDescription'),
          dialogDisagree: t('dialogDisagree'),
          dialogAgree: t('dialogAgree'),
        }}
        handleSubmit={props.handleSubmit}
        handleOpenDialog={openDialog => {
          // 開啟 Dialog 之前先進行 input 驗證，這樣操作體驗比較好
          props.validateForm().then(value => {
            // 若欄位驗證錯誤則提示錯誤訊息
            if (Object.keys(value).length > 0) {
              props.setTouched({
                parentid: !!value.parentid,
                gamecode: !!value.gamecode,
              })

              return
            }

            openDialog()
          })
        }}
      />

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

AddBlackList.propTypes = {
  allGamesOptions: PropTypes.array,
  agents: PropTypes.array,
  gamecode: PropTypes.array,
  parentid: PropTypes.array,
  onSubmit: PropTypes.func,
  isFetching: PropTypes.bool,
}

AddBlackList.defaultProps = {
  // 遊戲代號清單
  allGamesOptions: [],
  // 代理帳號清單
  agents: [],
  // renderfield 初始值
  gamecode: [],
  // renderfield 初始值
  parentid: [],
  // submit callback
  onSubmit: () => { },
  // 請求狀態
  isFetching: false,
}

export default compose(
  withTranslation('addBlackList'),
  formikEnhancer,
)(AddBlackList)
