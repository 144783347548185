const rawData = {
  noMatch: ['Sorry, no matching records found', '没有找到匹配的资料'],

  datetime: ['Date', '日期'],
  account: ['Player Account', '玩家帐号'],
  parentid: ['Agent Account', '代理帐号'],
  currency: ['Currency', '币别'],
  bets: ['Bet', '总投注额'],
  wins: ['Win', '总赢分'],
  jackpots: ['Jackpot', '总彩池'],
  validbet: ['Valid bet', '有效投注额'],
  roomfee: ['Room fee', '开房费'],
  playerincome: ['Player Profit', '玩家损益金额'],
  donate: ['Donate', '打賞金额'],
  totalround: ['Total Game Round', '总游戏场次'],
  betavg: ['Average Bets', '平均投注额'],

  tableBets: ['Total Bet', '总押注'],
  tableWins: ['Total Win', '总输赢'],
  rakes: ['Total Rake', '总抽水'],

  agentAcc: ['Agent Account', '代理帐号'],
  playerAcc: ['Player Account', '玩家帐号'],
  playerAccSearchMethod: ['Player Account Search Mode', '玩家帐号筛选方式'],
  fuzzySearch: ['Fuzzy Search', '模糊搜寻'],
  exactSearch: ['Exact Search', '精确搜寻'],
  criterion: ['Numerical Filtering', '数值筛选'],
  noFilter: ['Do not filter', '不筛选'],
  bet: ['Bet', '投注额'],
  validbets: ['Total Valid bets', '总有效投注额'],
  win: ['Win', '赢分'],
  amount: ['Amount', '数字'],
  less: ['less than', '小于等于'],
  more: ['more than', '大于等于'],
  groupby: ['Settlement Interval', '结算周期'],
  hour: ['Hour', '时'],
  day: ['Day', '日'],
  month: ['Month', '月'],
  year: ['Year', '年'],

  all: ['All', '全部'],
  gametype: ['Game Type', '游戏类型'],
  lotto: ['Lotto', '彩票'],
  sport: ['Sport', '体育'],
  allGame: ['Probability Game', '机率游戏'],
  table: ['Table Battle', '牌桌对战'],
  slot: ['Slot', '老虎机'],
  fish: ['Fish', '渔机'],
  arcade: ['Arcade', '街机'],

  tableType: ['Live Game Type', '真人游戏类型'],
  gameType: ['Game Type', '游戏类型'],
  tableId: ['Table No', '桌号'],

  // input error,
  required: ['This is a required field.', '此为必填项目。'],
  mustNumber: ['Must be a `number` type. ', '必须为数字。'],
  amountMinimum: ['Amount must be greater than or equal to 0', '金额必须大于或等于0'],
  playerAccMinimum: ['Player account minimum length of 4 words.', '玩家帐号最小长度为 4 个字。'],
  gamecode: ['Game Code', '游戏代号'],
  live: ['Live', '真人视讯'],
  livegame: ['Live Game', '弹珠游戏'],
  dealergame: ['Dealer Game', '荷官游戏'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
