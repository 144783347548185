import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import a from '../../actions'
import { qsParse } from '../../helpers'
import {
  getAgentById,
  getAgentTotalPlayersById,
  getUser,
  getTimeZone,
  getTheme,
  getIsRequesting,
  getGamesWithoutLottoAndSport,
  getLanguage,
  getBetThresholdGroupList,
  getBetThresholdIsSubmitting,
} from '../../reducers'

import { DARK_MODE } from '../../themes/bo'
import Container from '../../components/Container'
import Paper from '../../components/Paper'
import GridItem from '../../components/grids/GridItem'
import { getSavedState } from '../../helpers/storage'
import AgentDetailFormForAdmin from '../../components/forms/AgentDetailFormForAdmin'
import ChangePasswordForm from '../../components/forms/ChangePasswordForm'

import BetThresholdList from './BetThreshold/list'

import WalletForm from '../../components/forms/WalletForm'
import language from '../../locale/userProfile'

class AgentDetail extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      show: 0,
      isEditing: false,
      gotBetThresholdGroupList: [],
      isStation: false,
    }
  }

  componentDidMount () {
    const {
      agentId,
      fetchAgentDetail,
      fetchTotalSignUp,
      fetchBetThresholdGroup,
      show,
      i18n,
    } = this.props

    i18n.addResourceBundle('en', 'userProfile', language.en)
    i18n.addResourceBundle('cn', 'userProfile', language.cn)

    this.setState({ show })
    fetchAgentDetail({ userID: agentId })
    fetchTotalSignUp({ userID: agentId })
    fetchBetThresholdGroup({ userID: agentId })
  }

  componentDidUpdate (prevProps, prevState) {
    const { betThresholdGroupList, fetchStationList, agentsById, agentId } = this.props

    if (agentsById[agentId]?.info?.wallettype === 'ce' && this.state.isStation === false) {
      this.setState({ isStation: true })
      fetchStationList({ userID: agentId })
    }

    // todo shallow copy
    if (betThresholdGroupList !== prevProps.betThresholdGroupList) {
      this.setThresholdGroup(betThresholdGroupList)
    }
  }

  handleChangeTabs = (_, value) => {
    const { history, agentId } = this.props

    this.setState({ show: value })

    const location = {
      state: { show: value },
    }

    history.replace(location)

    this.fetchBetThresholdGroup(agentId)
  };

  handleEditing = () => {
    this.setState({ isEditing: true })
  };

  handleCancel = () => {
    this.setState({ isEditing: false })
  };

  onSubmitProfile = values => {
    const { isAdmin, handleAgentDetailSubmit, agent } = this.props

    const effects = []

    if ((agent.currencySign === true ? 'true' : 'false') !== values.currencySign) {
      effects.push('updateCurrencySign')
    }

    if (agent.status !== values.status) {
      effects.push('updateAgentStatus')
    }

    if (isAdmin && values.betThreshold.enabled === 'true') {
      effects.push('updateBetThreshold')
    }

    return handleAgentDetailSubmit(values, {
      effects,
      cb: () => {
        this.handleCancel()
      },
    })
  };

  onSubmitChangePassword = values => {
    this.props.updateAgentPassword(values)
  };

  onSubmitWallet = values => {
    this.props.updateAgentWallet(values)
  };

  onSubmitBetThreshold = (values, createMode) => {
    const { agentId } = this.props
    const payload = {
      userID: agentId,
      name: values.name,
      gameCodes: values.gameCodes,
      gameTypes: values.gameTypes,
      maximum: values.maximum,
      minimum: values.minimum,
      default: values.default,
      bettype_maximum: values.bettype_maximum,
      round_maximum: values.round_maximum,
      roombet: values.roombet,
    }

    if (createMode) {
      this.props.createBetThresholdGroup(payload)
    } else {
      payload.id = values.id
      this.props.updateBetThresholdGroup(payload)
    }
  }

  onDeleteBetThreshold = values => {
    const payload = {
      userID: this.props.agentId,
      id: values.id,
    }

    this.props.deleteBetThresholdGroup(payload)
  }

  onToggleThreshouldStatus = values => {
    const { agent, updateBetThresholdStatus } = this.props

    updateBetThresholdStatus({ userid: agent.userid, enabled: values })
  };

  onCopied = () => {
    this.props.addMsg({
      replaceWithI18nText: 'copiedSuccess',
      variant: 'success',
    })
  }

  setThresholdGroup = (group) => {
    this.setState({
      gotBetThresholdGroupList: this.assignThresholdData(group),
    })
  }

  fetchBetThresholdGroup = userID => {
    this.props.fetchBetThresholdGroup({ userID: userID })
  }

  // assignThresholdData
  // 將請求參數 @id,@userID放入action
  // 將最小最大值, 串成範圍值
  assignThresholdData = data => {
    if (Array.isArray(data)) {
      const assignData = data.map(obj => {
        obj.action = {
          id: obj.id,
          userID: obj.agentID,
          fields: obj,
        }
        obj.threshold = obj.minimum + ' - ' + obj.maximum
        return obj
      })
      return assignData
    }

    return data
  };

  render () {
    const {
      agent,
      totalPlayers,
      t,
      isAdmin,
      timezone,
      themeMode,
      isRequesting,
      allGamesOptions,
      betThresholdIsSubmitting,
      isTotalSignUpRequesting,
    } = this.props
    const { show, isEditing, gotBetThresholdGroupList } = this.state
    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            style={themeMode === DARK_MODE ? { backgroundColor: '#585858' } : {}}
            value={show}
            onChange={this.handleChangeTabs}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t('detail')} />
            <Tab label={t('changePwd')} />
            <Tab
              label={t('walletInfo')}
              style={{ display: agent && agent.sw ? 'flex' : 'none' }}
            />
            <Tab
              label={t('betThreshold')}
              style={{ display: agent && agent.betThreshold && agent.betThreshold.enabled ? 'flex' : 'none' }}
            />
          </Tabs>
        </AppBar>

        <Container>
          {agent && show === 0 && (
            <GridItem form>
              <Paper>
                <AgentDetailFormForAdmin
                  userid={agent.userid}
                  account={agent.account}
                  stationAccount={agent.stationAccount}
                  parentAccount={agent.parentacc}
                  signUpAt={moment(agent.signuptime * 1000).utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss')}
                  loginAt={
                    agent.logintime === 0
                      ? t('neverLogin')
                      : moment(agent.logintime * 1000).utcOffset(timezone * 60).format('YYYY-MM-DD HH:mm:ss')
                  }
                  totalPlayers={totalPlayers}
                  commission={agent.commission}
                  ssToken={agent.sstoken}
                  status={agent.status}
                  isTestSS={agent.isTestSS === true ? 'true' : 'false'}
                  currencySign={agent.currencySign === true ? 'true' : 'false'}
                  betThreshold={{
                    ...agent.betThreshold,
                    enabled: agent && agent.betThreshold && agent.betThreshold.enabled === true ? 'true' : 'false',
                  }}
                  onSubmit={this.onSubmitProfile}
                  handleToggleBetThreshold={this.onToggleThreshouldStatus}
                  handleEditing={this.handleEditing}
                  handleCancel={this.handleCancel}
                  onCopied={this.onCopied}
                  isEditing={() => isEditing}
                  isAdmin={() => isAdmin}
                  isRequesting={isRequesting}
                  isTotalSignUpRequesting={isTotalSignUpRequesting}
                />
              </Paper>
            </GridItem>
          )}

          {agent && show === 1 && (
            <GridItem form>
              <Paper>
                <ChangePasswordForm
                  account={agent.account}
                  password=""
                  confirmPassword=""
                  onSubmit={this.onSubmitChangePassword}
                />
              </Paper>
            </GridItem>
          )}

          {agent && show === 2 && agent.sw && (
            <GridItem form>
              <Paper>
                <WalletForm
                  userid={agent.userid}
                  // wtoken={agent.wtoken}
                  wurl={agent.wurl}
                  onSubmit={this.onSubmitWallet}
                  handleEditing={this.handleEditing}
                  handleCancel={this.handleCancel}
                  onCopied={this.onCopied}
                  isEditing={isEditing}
                />
              </Paper>
            </GridItem>
          )}
          {agent && show === 3 && agent.betThreshold.enabled && (
            <GridItem fluid xs={12} sm={12} md={10} lg={8} xl={5}>
              <Paper>
                <BetThresholdList
                  data={gotBetThresholdGroupList}
                  // deleteFunc={this.onDeleteBetThresholdGroup}
                  allGamesOptions={allGamesOptions}
                  betThresholdGroupList={gotBetThresholdGroupList}
                  agent={agent}
                  isSubmitting={betThresholdIsSubmitting}
                  onSubmit={this.onSubmitBetThreshold}
                  onDelete={this.onDeleteBetThreshold}
                />
              </Paper>
            </GridItem>
          )}
        </Container>
      </>
    )
  }
}

const mapStateToProps = (state, { location, match }) => {
  const agentsById = state.agents.byId
  const search = location ? qsParse(location.search) : null
  const agentId = match.params.agentId
  const agent = getAgentById(state, agentId)
  const totalPlayers = getAgentTotalPlayersById(state, agentId)
  const show = location.state && location.state.show ? location.state.show : 0
  const user = getUser(state)
  const isAdmin = user && user.permit === 'admin'
  const timezone = getSavedState('timezone') || getTimeZone(state)
  const themeMode = getTheme(state)
  const isRequesting = getIsRequesting(state, 'handleAgentDetailSubmit')
  const isTotalSignUpRequesting = getIsRequesting(state, 'fetchTotalSignUp')
  const language = getLanguage(state)
  const allGamesOptions = getGamesWithoutLottoAndSport(state, language)
  const betThresholdGroupList = getBetThresholdGroupList(state)
  const betThresholdIsSubmitting = getBetThresholdIsSubmitting(state)
  return {
    agentsById,
    themeMode,
    agentId,
    agent,
    totalPlayers,
    search,
    show,
    isAdmin,
    timezone,
    isRequesting,
    allGamesOptions,
    betThresholdGroupList,
    betThresholdIsSubmitting,
    isTotalSignUpRequesting,
  }
}

export default connect(
  mapStateToProps,
  {
    // agent
    fetchAgentDetail: a.agent.fetchDetail,
    fetchStationList: a.agent.fetchStationList,
    fetchTotalSignUp: a.agent.fetchTotalSignUp,
    updateAgentStatus: a.agent.updateStatus,
    updateAgentPassword: a.agent.updatePassword,
    updateAgentWallet: a.agent.updateWallet,
    // bet threshould
    updateBetThresholdStatus: a.betThreshold.updateStatus,
    // event
    handleAgentDetailSubmit: a.event.handleAgentDetailSubmit,
    // msgs
    addMsg: a.msgs.add,
    // create bet threshould group
    createBetThresholdGroup: a.betThreshold.createGroup,
    // update bet threshold group
    updateBetThresholdGroup: a.betThreshold.updateGroup,
    // delete bet threshold group
    deleteBetThresholdGroup: a.betThreshold.deleteGroup,
    // fetch bet threshold group
    fetchBetThresholdGroup: a.betThreshold.fetchGroup,
  },
)(withTranslation('userProfile')(AgentDetail))
