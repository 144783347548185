import React, { useCallback, useEffect, useState } from 'react'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'
import { api } from '../../services'
import { renderFieldsWithGridItem } from './helper'
import GridContainer from '../grids/GridContainer'

import OutlinedTextField from '../text-fields/OutlinedTextField'
import SelectTextField from '../text-fields/SelectTextField'
import RadioTextField from '../text-fields/RadioTextField'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    marginTop: theme.spacing.unit * 1,
    marginBottom: theme.spacing.unit * 1,
  },
})

const formikEnhancer = withFormik({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      if (values?.wallettype === 'singleV1') {
        await props.onSubmit({ ...values, wallettype: 'single', newsw: false })
      } else if (values?.wallettype === 'singleV2') {
        await props.onSubmit({ ...values, wallettype: 'single', newsw: true })
      } else {
        await props.onSubmit(values)
      }
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'AgentListForAdminForm',
})

const AgentListForAdminForm = props => {
  const { isSubmitting, classes, t, isFetching, currencyOptions, isAdmin, role, permit } = props
  const [brandList, setBrandList] = useState([])

  const fetchBrandList = useCallback(async () => {
    const { data: { data: brandOptions } } = await api.get(api.BO, '/brand/list')
    const { data: { data: userInfo } } = await api.get(api.BO, '/user/info')
    console.log('brandOptions', brandOptions)

    const isAdmin = role === 'admin' || (role === 'cs' && permit === 'admin')
    const adminOption = [
      { label: t('all', { ns: 'common' }), value: '' },
      ...brandOptions.map((el) => ({
        label: el,
        value: el,
      })),
    ]
    const brandSelectOptions = isAdmin ? adminOption : [userInfo.brand]

    setBrandList(brandSelectOptions)
  }, [role, permit])

  useEffect(() => {
    fetchBrandList()
  }, [])

  const fields = [
    [
      {
        name: 'account',
        label: t('agentAcc'),
        fullWidth: true,
        isClearable: true,
        InputLabelProps: {
          shrink: true,
        },
        Field: OutlinedTextField,
      },
      {
        Field: SelectTextField,
        name: 'currency',
        label: t('currency'),
        fullWidth: true,
        options: [
          { label: t('notScreened', { ns: 'common' }), value: '' },
          ...currencyOptions(),
        ],
      },
      {
        Field: SelectTextField,
        name: 'wallettype',
        label: t('wallettype'),
        fullWidth: true,
        options: [
          { label: t('notScreened', { ns: 'common' }), value: '' },
          { label: t('transferWallet', { ns: 'common' }), value: 'transfer' },
          { label: t('singleWallet', { ns: 'common' }), value: 'singleV1' },
          { label: t('singleWalletV2', { ns: 'common' }), value: 'singleV2' },
        ],
      },
    ],
    [
      {
        Field: RadioTextField,
        name: 'brand',
        label: t('gamehall', { ns: 'common' }),
        fullWidth: true,
        options: brandList,
      },
    ],
  ]

  return (
    <Form className={classes.form}>
      <GridContainer>{renderFieldsWithGridItem(fields[0], props)}</GridContainer>
      {
        isAdmin && (<GridContainer>
          {renderFieldsWithGridItem(fields[1], props)}
        </GridContainer>)
      }
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isSubmitting}
      >
        {t('search', { ns: 'common' })}
      </Button>

      {isFetching && <CircularProgress disableShrink={true} thickness={4.8} size={24} style={{ marginLeft: 15, position: 'relative', top: 9 }} />}

    </Form>
  )
}

export default formikEnhancer(
  withStyles(styles)(withTranslation('agentList')(AgentListForAdminForm)),
)
