const rawData = {
  TOTAL: ['Summary', '小计'],
  SLOT: ['Probability Game Summary', '机率游戏小计'],
  TABLE: ['Table Battle Summary', '牌桌对战小计'],
  LIVE: ['Live Casino Summary', '真人视讯小计'],
  LIVEGAME: ['Live Game Summary', '弹珠游戏小计'],
  DEALERGAME: ['Dealer Game Summary', '荷官游戏小计'],

  // 交易查询
  bet: ['Bets', '投注'],
  win: ['Wins', '赢分'],
  rollin: ['Rollin', '转入'],
  rollout: ['Rollout', '转出'],
  takeall: ['Takeall', '全转出'],
  credit: ['Credit', '补款'],
  debit: ['Debit', '扣款'],
  payoff: ['Payoff', '派彩'],
  refund: ['Refund', '退款'],
  cashin: ['Deposit', '存款'],
  cashout: ['Withdraw', '提款'],

  // 代理报表
  totalgamelogin: ['Games Opened', '游戏开启次数'],
  totalsignup: ['Total Signup', '新进玩家'],
  totalround: ['Total Game Round', '总游戏场次'],
  betavg: ['Average Bets', '平均投注额'],

  // 总返还率 ------------------------------------------------
  totalrtp: ['Total RTP', '总返还率'],
  // 不分代理游戏报表
  rtp: ['Total RTP', '总返还率'],

  jackpots: ['Total Jackpots', '总彩池'],
  bets: ['Total Bets', '总投注'],
  wins: ['Total Wins', '总赢分'],

  // 总抽水 ------------------------------------------------
  rakes: ['Total Rakes', '总抽水'],
  // 不分代理游戏报表
  rake: ['Total Rakes', '总抽水'],

  tableBets: ['Total Bets', '总押注'],
  tableWins: ['Total Wins', '总输赢'],

  totalbets: ['Player Total Bets', '玩家总投注'],
  totalwins: ['Player Total Wins', '玩家总赢分'],
  totaljackpots: ['Player Total Jackpots', '玩家总彩池'],
  playerincome: ['Player Profit / Loss', '玩家损益金额'],
  parentincome: ['Agent Profit / Loss', '代理损益金额'],

  totallivebets: ['Player Total Bets', '玩家总押注额'],
  totalTableBets: ['Player Total Bets', '玩家总押注额'],
  totaltablebets: ['Player Total Bets', '玩家总押注额'],

  totallivewins: ['Player Winloss', '玩家总输赢'],
  totalTableWins: ['Player Winloss', '玩家总输赢'],
  totaltablewins: ['Player Winloss', '玩家总输赢'],
  totalRake: ['Player Total Rake', '玩家总抽水'],
  totalrake: ['Player Total Rake', '玩家总抽水'],
  playerIncome: ['Player Profit / Loss', '玩家损益金额'],
  parentIncome: ['Agent Profit / Loss', '代理损益金额'],
  totalSignup: ['Total Signup', '新进玩家'],
  totalRound: ['Total Game Round', '总游戏场次'],
  betAvg: ['Average Bets', '平均投注额'],
  totalavgbet: ['Total Average Bets', '平均投注额'],

  // 总有效投注 ------------------------------------------------
  // 体彩报表
  validbets: ['Total Valid bets', '总有效投注额'],
  // 报表中心
  validBet: ['Total Valid bets', '总有效投注额'],
  // 注单查询
  totalValidBets: ['Total valid bets', '总有效投注额'],
  totalvalidbets: ['Total valid bets', '总有效投注额'],

  // 总开房费 -------------------------------------------------
  // 报表中心
  roomFee: ['Total room fee', '总开房费'],
  // 注单查询
  totalRoomFee: ['Total room fee', '总开房费'],
  totalroomfee: ['Total room fee', '总开房费'],

  // 红利 -------------------------------------------------
  bonus: ['Bonus', '红利'],
  // 注单查询
  roomfee: ['Total room fee', '总开房费'],
  // 总派彩
  totalpayoff: ['Total Amount', '总派彩金额'],

  // 免费券
  FREEGAME: ['Free Game Ticket', 'Free Game 券'],
  FREESPIN: ['Free Spin Ticket', 'Free Spin 券'],
  totalrounds: ['Total Rounds', '总场次'],
  totalticketbets: ['Total Ticket Bets', '总免费券底注'],

  // 打賞
  totaldonate: ['Total Donate', '打赏金额'],
}

const language = { en: {}, cn: {} }
Object.keys(rawData).forEach(key => {
  language.en[key] = rawData[key][0]
  language.cn[key] = rawData[key][1]
})

export default language
