import React, { Component } from 'react'
import * as Yup from 'yup'
import { Form, withFormik } from 'formik'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { withTranslation } from 'react-i18next'

import OutlinedTextField from '../text-fields/OutlinedTextField'
import OutlinedCopyTextField from '../text-fields/OutlinedCopyTextField'

import { renderFields } from './helper'
import GridItem from '../grids/GridItem'
import GridContainer from '../grids/GridContainer'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
})

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    wurl: Yup.string()
      .url('mustUrl')
      .required('required'),
    // wtoken: Yup.string(),
  }),
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
      props.handleCancel()
    } catch (error) {}

    setSubmitting(false)
  },
  displayName: 'WalletForm',
})

class WalletForm extends Component {
  render () {
    const {
      t,
      theme,
      isSubmitting,
      classes,
      resetForm,
      handleEditing,
      handleCancel,
      isEditing,
    } = this.props

    const fields = [
      [
        {
          name: 'wurl',
          label: t('walletLink'),
          fullWidth: true,
          InputProps: { readOnly: !isEditing },
          Field: OutlinedTextField,
        },
      ],
      // [
      //   isEditing
      //     ? {
      //       name: 'wtoken',
      //       label: t('walletToken'),
      //       fullWidth: true,
      //       Field: OutlinedTextField,
      //     }
      //     : {
      //       name: 'wtoken',
      //       label: t('walletToken'),
      //       fullWidth: true,
      //       InputProps: { readOnly: true },
      //       Field: OutlinedCopyTextField,
      //       onCopied: () =>
      //         this.props.onCopied(),
      //     },
      // ],
    ]

    return (
      <Form>
        <h3 style={ theme.customer.H3Style }>{t('walletInfo')}</h3>

        {renderFields(fields[0], this.props)}

        {/* <h3 style={ theme.customer.H3Style }>{t('token')}</h3>

        {renderFields(fields[1], this.props)} */}

        <GridContainer>
          <GridItem fluid>
            <Button
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handleEditing}
              disabled={isSubmitting || isEditing}
            >
              {t('edit', { ns: 'common' })}
            </Button>
          </GridItem>
          {isEditing && (
            <>
              <GridItem fluid>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  {t('save', { ns: 'common' })}
                </Button>
              </GridItem>
              <GridItem fluid>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                  onClick={() => {
                    resetForm()
                    handleCancel()
                  }}
                >
                  {t('cancel', { ns: 'common' })}
                </Button>
              </GridItem>
            </>
          )}
        </GridContainer>
      </Form>
    )
  }
}

export default formikEnhancer(withStyles(styles, { withTheme: true })(withTranslation('userProfile')(WalletForm)))
